<template>
  <Layout>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">
            Home
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ device.name }} Stats
        </li>
      </ol>
    </nav>
    <div class="my-3 my-md-5">
      <div class="container">
        <div :class="['dimmer', !loaded ? 'active' : '']">
          <div class="loader"></div>
          <div class="dimmer-content">
            <Chart :options="chartOptions"></Chart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import getTime from 'date-fns/getTime';
import { Chart } from 'highcharts-vue';

export default {
  name: 'TapStats',
  components: {
    Layout,
    Chart,
  },
  props: { device: Object },
  data: () => ({
    loaded: false,
    chartOptions: {
      chart: {
        type: 'line',
        backgroundColor: '#f5f7fb',
      },
      title: {
        text: null,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000, // one week
        tickWidth: 0,
        gridLineWidth: 1,
        labels: {
          align: 'left',
          x: 3,
          y: -3,
        },
        units: [
          [
            'millisecond', // unit name
            [1, 2, 5, 10, 20, 25, 50, 100, 200, 500], // allowed multiples
          ],
          ['second', [1, 2, 5, 10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]],
          ['hour', [1, 2, 3, 4, 6, 8, 12]],
          ['day', [1]],
          ['week', [1]],
          ['month', [1, 3, 6]],
          ['year', null],
        ],
      },
      yAxis: {
        title: {
          text: 'Percentage',
        },
        min: 0,
        max: 100,
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x:%b %e}: {point.y}%',
      },

      plotOptions: {
        series: {
          animation: false,
          connectNulls: false,
          marker: {
            enabled: true,
          },
        },
      },

      colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

      // Define the data points. All series have a dummy year
      // of 1970/71 in order to be compared on the same x axis. Note
      // that in JavaScript, months start at 0 for January, 1 for February etc.
      series: [],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  marker: {
                    radius: 2.5,
                  },
                },
              },
            },
          },
        ],
      },
    },
  }),
  async mounted() {
    const deviceStats = await this.$store.dispatch('devices/getStatsForTap', {
      deviceId: this.device.id,
    });

    const batteryStrength = [];
    const wifiStrength = [];

    const stats = deviceStats
      .map((x) => ({ b: x.battery, w: x.wifiStrength, c: new Date(x.created) }))
      .sort((a, b) => a.c - b.c)
      .reduce((r, a) => {
        r[format(a.c, 'MM/dd/yy')] = [...(r[format(a.c, 'MM/dd/yy')] || []), a];
        return r;
      }, {});

    Object.entries(stats).forEach(([key, value]) => {
      const batt = value.reduce((p, c) => p + c.b, 0);
      const wifi = value.reduce((p, c) => p + c.w, 0);

      const dt = getTime(parse(key, 'MM/dd/yy', new Date()));

      batteryStrength.push([dt, +((batt / value.length) * 100).toFixed()]);
      wifiStrength.push([dt, +((wifi / value.length) * 100).toFixed()]);
    });

    this.chartOptions.series.push({
      regression: true,
      regressionSettings: {
        type: 'linear',
        color: 'rgba(223, 83, 83, .9)',
      },
      name: 'Battery Strength',
      data: batteryStrength,
      color: 'green',
    });

    this.chartOptions.series.push({
      regression: true,
      regressionSettings: {
        type: 'linear',
        color: 'rgba(223, 83, 83, .9)',
      },
      name: 'Wifi Strength',
      data: wifiStrength,
      color: 'red',
    });

    this.loaded = true;
  },
};
</script>

<style>
.chart-stats {
  min-height: 300px !important;
  max-height: 400px !important;
}
</style>
